import axios from "@axios";

export default {
  namespaced: true,
  state: { certificateData: {} },
  getters: {
    getCertificate(state) {
      return state.certificateData;
    },
  },
  mutations: {
    setCertificate(state, param) {
      state.certificateData = param;
    },
  },
  actions: {
    fetchInstructers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/lms/admin/course/certificates", { roleid: 7 })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async fetchCertificate(ctx, { id }) {
      //console.log("single course  Fetching");
      const res = await new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/certificatedetail/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
      ctx.commit("setCertificate", res.data);
      return res.data;
    },

    fetchInstructer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getinstructordetail/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCoursesbyInstructers(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursesbyinstructer/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateInstructerStatus(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/lms/admin/course/updateinstructerstatus", { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    deleteInstructer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/lms/admin/course/deleteinstructer", { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateInstructorphoto(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/lms/admin/course/updateinstructorphoto", { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createInstructor(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/lms/admin/course/createcertificate", userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
